import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from '@zd/theme';

const Label = styled.label`
  color: ${theme.colors.mainGrey};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 20px;
  text-transform: uppercase;
`;

const InputElement = styled.input`
  border: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #b1acbd;
  width: 100%;
  background: transparent;

  &::placeholder {
    color: #545454;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
  }
`;

const Input = props => {
  const {
    type,
    label,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    required,
    disabled,
    hasError,
    forceError,
    ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  return (
    <div>
      <Label>{label}</Label>
      <InputElement
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={() => {
          setTouched(true)
          onBlur()
        }}
        required={required}
        disabled={disabled}
        style={{borderColor: (hasError && touched || hasError && forceError) ? 'red' : '#b1acbd'}}
        {...rest}
      />
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  forceError: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  onChange: () => undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
  required: false,
  disabled: false,
  hasError: false,
  forceError: false,
};

export default Input;
